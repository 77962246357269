var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-input-search",
    {
      staticStyle: { width: "100%" },
      attrs: {
        placeholder: _vm.$t("lbl_batch_number"),
        "allow-clear": "",
        loading: _vm.loading,
        value: _vm.value,
        disabled: _vm.isDisabled
      },
      on: {
        input: _vm.onInput,
        search: function(val) {
          return _vm.onSearch(val)
        }
      }
    },
    [
      _c(
        "a-button",
        {
          attrs: {
            slot: "enterButton",
            type: "primary",
            disabled: _vm.isDisabled
          },
          slot: "enterButton"
        },
        [
          _vm._t("button-text", function() {
            return [_vm._v(" " + _vm._s(_vm.$t("lbl_generate")) + " ")]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }